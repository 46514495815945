import { defaultStyles } from '@/utils/defaultStyles';

function TrainingCard(props: { title: string }) {
  return (
    <div className="flex flex-row gap-4">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="min-w-[16px] min-h-[16px] max-w-[16px] max-h-[16px]"
      >
        <g clipPath="url(#clip0_21_432)">
          <path
            d="M7.00101 11.3332C6.82571 11.3333 6.65209 11.2989 6.49011 11.2319C6.32813 11.1648 6.18095 11.0665 6.05701 10.9425L3.53435 8.47653C3.40793 8.35277 3.33585 8.18385 3.33398 8.00694C3.3321 7.83003 3.40058 7.65962 3.52435 7.5332C3.64811 7.40678 3.81703 7.3347 3.99394 7.33283C4.17085 7.33095 4.34126 7.39943 4.46768 7.5232L6.99635 9.99453L11.5337 5.52787C11.6616 5.41528 11.8278 5.35598 11.9982 5.36214C12.1685 5.3683 12.33 5.43946 12.4495 5.56099C12.5689 5.68252 12.6373 5.8452 12.6406 6.0156C12.6439 6.186 12.5818 6.35118 12.467 6.4772L7.93835 10.9485C7.81508 11.0711 7.66884 11.1682 7.50799 11.2342C7.34715 11.3002 7.17487 11.3339 7.00101 11.3332ZM16.001 12.6665V8.22653C16.0259 6.1715 15.2724 4.18313 13.8919 2.66065C12.5114 1.13816 10.606 0.194291 8.55835 0.0185323C7.41545 -0.0614292 6.2687 0.105025 5.1957 0.506634C4.1227 0.908243 3.14848 1.53564 2.33896 2.34636C1.52943 3.15709 0.903479 4.13224 0.503462 5.20584C0.103444 6.27943 -0.0613104 7.42642 0.0203454 8.5692C0.315012 12.8052 4.05568 15.9999 8.72301 15.9999H12.6677C13.5514 15.9988 14.3986 15.6473 15.0235 15.0224C15.6484 14.3975 16 13.5503 16.001 12.6665ZM8.46768 1.3492C10.1787 1.50056 11.769 2.29381 12.9191 3.56957C14.0692 4.84533 14.6939 6.50908 14.6677 8.22653V12.6665C14.6677 13.197 14.457 13.7057 14.0819 14.0807C13.7068 14.4558 13.1981 14.6665 12.6677 14.6665H8.72301C4.70035 14.6665 1.60101 12.0665 1.35101 8.4772C1.28538 7.56292 1.409 6.64493 1.71413 5.78058C2.01927 4.91623 2.49938 4.12409 3.12446 3.45365C3.74954 2.78321 4.50616 2.24887 5.34706 1.88403C6.18795 1.51919 7.09505 1.33167 8.01168 1.3332C8.16301 1.3332 8.31568 1.3392 8.46768 1.3492Z"
            fill="#0D81FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_21_432">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.00112915)"
            />
          </clipPath>
        </defs>
      </svg>
      <div className="text-[16px] text-[#8AA7C8]">{props.title}</div>
    </div>
  );
}

const items = [
  'Rozwijanie potencjału i realizacja celów',
  'Zwiększanie pewności siebie i spokoju w stresujących sytuacjach',
  'Określenie potrzeb, wartości, celów, motywatorów, trudności',
  'Zarządzanie energią i stresem, techniki uwalniania napięć',
  'Poprawa koncentracji podczas zadań',
  'Techniki relaksacyjne, wizualizacja, trening wyobrażeniowy',
  'Radzenie sobie z porażkami w życiu prywatnym i zawodowym',
  'Naprawa relacji i komunikacji z innymi oraz z samym sobą',
  'Poprawa jakości życia, tworzenie lepszych nawyków, samorealizacja',
];

export default function TrainingRoleSection() {
  return (
    <section
      className={`flex flex-col bg-white pb-24 ${defaultStyles.paddingBig}`}
      id="services"
    >
      <div className={`flex flex-row justify-between ${defaultStyles.padding}`}>
        <div className="flex flex-col 2xl:flex-row gap-8 2xl:gap-4 justify-between">
          <div className="flex flex-col gap-8">
            <div className="text-[35px] text-black">
              <span className="font-bold text-[#0D81FF]">
                Moją rolą jako trenera mentalnego
              </span>{' '}
              w pracy z klientem jest m.in.:
            </div>
            <div className="flex flex-col gap-2">
              {items.map((item, index) => (
                <TrainingCard title={item} key={index} />
              ))}
            </div>
          </div>
          <img
            alt="Galeria"
            loading="lazy"
            className="w-[90%] md:w-[70%] lg:w-[60%] 2xl:w-[50%]"
            src="/training-role.png"
          />
        </div>
      </div>
    </section>
  );
}
