import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow, CustomRightArrow } from './CustomCarouselArrows';

function Slide(props: {
  name: string;
  job: string;
  avatar: string;
  review: string;
}) {
  return (
    <div className="flex flex-col gap-4 mx-2">
      <div className="bg-white min-h-[220px] rounded-[26px] px-4 py-8 text-black text-[16px]">
        {props.review}
      </div>
      <img
        loading="lazy"
        alt={props.name}
        src={props.avatar}
        width={65}
        height={65}
      />
      <div className="text-white font-bold text-[25px]">{props.name}</div>
      <div className="text-white text-[14px]">{props.job}</div>
    </div>
  );
}

const items = [
  {
    name: 'Anna Kaczmarska',
    job: 'Ekspert ds. Kadr i Płac w firmie informatycznej',
    avatar: '/profile-1.png',
    review:
      'Patryk to niezwykle inspirujący człowiek! Jego profesjonalne warsztaty dają niesamowitą energię i motywacje do działania nawet w momencie totalnego uczucia zmęczenia i rezygnacji. Dziękuję Ci Patryk za pracę, którą wykonujesz każdego dnia. Takie chwilowe zatrzymanie i spojrzenie na własne emocje jest potrzebne w naszej codzienności. Zarażasz uśmiechem i pozytywnym nastawieniem do życia...',
  },
  {
    name: 'Tomasz P. Misztal',
    job: 'Wykładowca ATM twórca kursów "Laboratorium Mózgu" i "NeuroCoreReader"; rekordzista Polski w żonglowaniu kulami 3kg',
    avatar: '/profile-2.png',
    review:
      'Patryk to profesjonalny trener mentalny i tancerz. Daje poczucie bezpieczeństwa potrzebne do transformacji i skutecznej pracy nad sobą. Ma świadomość tego jak kluczowe jest połączenie pracy ciała i umysłu',
  },
  {
    name: 'Malwina Krzyczkowska',
    job: 'Mistrzyni Polski w kolarstwie górskim XC kat. Juniorka Młodsza w sztafecie mieszanej',
    avatar: '/profile-3.png',
    review:
      'Praca z Patrykiem dała mi więcej pewności siebie i dostrzegania swoich zalet by móc być jeszcze lepszą w tym co robię',
  },
  {
    name: 'Kamil Kłyż',
    job: 'Współzałożyciel firmy BYNA',
    avatar: '/profile-4.png',
    review:
      'Działając w branży budowlanej mam jedno nieodłączne skojarzenie z Patrykiem jako trenerem. Otóż, podobnie jak prawdziwy fachowiec posiada zestaw różnych technik i narzędzi niezbędnych do pracy, tyle, że w treningu mentalnym. Prawdziwy profesjonalista z indywidualnym i nieszablonowym podejściem do klienta. Polecam i dziękuję za to co przerobiliśmy.',
  },
  {
    name: 'Julia Dębska',
    job: 'Wielokrotna finalistka MP Juniorów w pływaniu stylem dowolnym',
    avatar: '/profile-5.png',
    review:
      'Współpraca z Patrykiem była bardzo przyjemna i komfortowa, czułam się zaopiekowana i zrozumiana. Uświadomił mnie o istnieniu małych trików, dzięki którym wyzwania stają się łatwiejsze. Teraz mam zasoby by działać lepiej z otoczeniem i w otoczeniu.',
  },
];

export default function CarouselSection() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1600 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      className={`flex flex-col bg-white px-0 lg:px-24 xl:px-32 2xl:px-52`}
    >
      <div
        className={`flex flex-col gap-8 py-12 rounded-[75px] ${defaultStyles.padding}`}
        style={createStyleForBackgroundImage('/carousel-bg.png', {
          withDarkOverlay: false,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        })}
      >
        <div className="text-white text-[40px]">
          <span className="font-bold">Sprawdź opinie</span>
          <br />
          moich klientów
        </div>
        <Carousel
          responsive={responsive}
          slidesToSlide={1}
          autoPlay
          autoPlaySpeed={4000}
          infinite
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          className="pb-16"
        >
          {items.map((item, index) => (
            <Slide {...item} key={index} />
          ))}
        </Carousel>
      </div>
    </section>
  );
}
