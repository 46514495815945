import CarouselSection from '@/components/CarouselSection';
import ContactFormSection from '@/components/ContactFormSection';
import HeroSection from '@/components/HeroSection';
import MeSection from '@/components/MeSection';
import PricingSection from '@/components/PricingSection';
import TrainingRoleSection from '@/components/TrainingRoleSection';
import Head from 'next/head';

export default function Home() {
  return (
    <>
      <Head>
        <title>Strona główna</title>
        <meta name="description" content="Strona główna firmy MentalStep" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <HeroSection />
      <TrainingRoleSection />
      <CarouselSection />
      <MeSection />
      <PricingSection />
      <ContactFormSection />
    </>
  );
}
