import { defaultStyles } from '@/utils/defaultStyles';

export default function MeSection() {
  return (
    <section
      className={`flex flex-col bg-white py-24 ${defaultStyles.paddingBig}`}
      id="about-me"
    >
      <div className={`flex flex-row justify-between ${defaultStyles.padding}`}>
        <div className="flex flex-col 2xl:flex-row gap-8 2xl:gap-8 justify-between">
          <div className="flex flex-col gap-8">
            <div className="text-[40px] text-black">Kim Jestem?</div>
            <div className="text-[#8AA7C8] text-[16px]">
              Jestem{' '}
              <span className="font-bold text-[#0D81FF]">
                Certyfikowanym Trenerem Mentalnym (w tym również o specjalizacji
                SPORT)
              </span>
              <br />
              Ukończyłem Akademię Trenerów Mentalnych Jakuba B. Bączka, który
              jako trener mentalny wraz z naszą reprezentacją zdobył złoty medal
              na Mistrzostwach Świata w 2014 roku
            </div>
            <div className="text-[#8AA7C8] text-[16px]">
              Jestem również absolwentem uczelni ASBiRO na kierunku "Zarządzanie
              Przedsiębiorstwem".
            </div>
            <div className="text-[#8AA7C8] text-[16px]">
              Przez wiele lat trenowałem wyczynowo najpierw siatkówkę w
              młodzieńczych latach, a następnie taniec towarzyski i posiadam
              aktualnie najwyższą krajową klasę A w tańcach latynoamerykańskich
              Polskiego Towarzystwa Tanecznego.
            </div>
            <div className="text-[#8AA7C8] text-[16px]">
              Obecnie prowadzę warsztaty grupowe oraz sesje indywidualne z
              dorosłymi i młodzieżą, zarówno w trybie online, jak i
              stacjonarnie. Współpracuję z różnymi firmami (m.in. Symfonia Sp. z
              o.o.), a także pracuję indywidualnie z młodymi przedsiębiorcami i
              sportowcami. Do tej pory głównie z tancerzami, piłkarzami,
              siatkarzami, kolarzami MTB i pływakami.
            </div>
          </div>
          <img
            alt="Patryk Gąsiorek"
            loading="lazy"
            className="w-[90%] md:w-[70%] lg:w-[60%] 2xl:max-w-[610px] 2xl:max-h-[516px]"
            src="/patryk-2.png"
          />
        </div>
      </div>
    </section>
  );
}
