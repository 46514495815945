export const CustomRightArrow = ({
  bgColor,
  svgColor,
  onClick,
  ...rest
}: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <div
      className={`absolute bottom-0 left-14 rounded-full w-[45px] h-[45px] bg-white flex flex-col justify-center items-center cursor-pointer`}
      onClick={() => onClick()}
    >
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8422 11.6373L16.5671 8.30318C16.3424 8.07504 15.9765 8.07157 15.7483 8.29624C15.5202 8.5209 15.5167 8.88686 15.7414 9.11616L19.0199 12.4538C19.2365 12.6703 19.5388 13.0015 19.848 13.3501C19.9997 13.5215 20.1074 13.7161 20.1792 13.9211H8.13044C7.8108 13.9211 7.55139 14.1805 7.55139 14.5001C7.55139 14.8197 7.8108 15.0792 8.13044 15.0792H20.1792C20.1062 15.2841 19.9997 15.4787 19.8468 15.6501C19.5388 15.9987 19.2365 16.3299 19.0165 16.5499L15.7414 19.8841C15.5167 20.1122 15.5202 20.4793 15.7483 20.704C15.8618 20.814 16.0078 20.8696 16.1548 20.8696C16.3042 20.8696 16.4548 20.8117 16.5671 20.697L19.8387 17.3664C20.068 17.1371 20.3888 16.7873 20.7142 16.4191C21.6835 15.3258 21.6835 13.6767 20.7142 12.5835C20.3876 12.2152 20.0669 11.8643 19.8422 11.6396V11.6373Z"
          fill="#0D81FF"
        />
      </svg>
    </div>
  );
};

export const CustomLeftArrow = ({
  bgColor,
  svgColor,
  onClick,
  ...rest
}: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <div
      className={`absolute bottom-0 left-0 rounded-full w-[45px] h-[45px] bg-white flex flex-col justify-center items-center cursor-pointer`}
      onClick={() => onClick()}
    >
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.15776 17.3627L12.4328 20.6968C12.6575 20.925 13.0235 20.9284 13.2516 20.7038C13.4797 20.4791 13.4832 20.1131 13.2585 19.8838L9.98 16.5462C9.76344 16.3297 9.46118 15.9985 9.15197 15.6499C9.00026 15.4785 8.89255 15.2839 8.82075 15.0789L20.8695 15.0789C21.1891 15.0789 21.4485 14.8195 21.4485 14.4999C21.4485 14.1803 21.1891 13.9208 20.8695 13.9208L8.82075 13.9208C8.89371 13.7159 9.00026 13.5213 9.15312 13.3499C9.46117 13.0013 9.76344 12.6701 9.98347 12.4501L13.2585 9.11593C13.4832 8.88779 13.4797 8.52067 13.2516 8.29601C13.1381 8.18599 12.9922 8.1304 12.8451 8.1304C12.6957 8.1304 12.5452 8.1883 12.4328 8.30295L9.16123 11.6336C8.93193 11.8629 8.61114 12.2127 8.28572 12.5809C7.3164 13.6742 7.3164 15.3233 8.28572 16.4165C8.6123 16.7848 8.93309 17.1357 9.15776 17.3604L9.15776 17.3627Z"
          fill="#0D81FF"
        />
      </svg>
    </div>
  );
};
